<template>
  <div>
    <div class="container mb-12">
      <div class="flex flex-row mt-6 mb-6">
        <a class="link mr-2" href='/news'>ข่าวสารและกิจกรรม</a>
        <a class="link mr-2">/</a>
        <a class="linkActive mr-2" href='/news/1'>รายละเอียดข่าวประชาสัมพันธ์</a>
      </div>

      <div class="cardCustom p-8">
        <img
          class="w-full object-contain"
          src="@/assets/mog/card1.png"
          alt=""
        />
        <div class="title mt-4 mb-4">
          ประกาศผลการพิจารณาโครงการกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
        </div>

        <div class="content">
          <div class="mb-4">
            ประกาศผลการพิจารณาโครงการ / กิจกรรมที่ได้รับการสนับสนุน
            จากกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม ประจำปีงบประมาณ พ.ศ. ๒๕๖๓
            ครั้งที่ ๒ ประเภทคำขอที่มีวงเงินไม่เกิน / เกิน ๑๐ ล้าน
          </div>

          <div class="mb-4">
            เมื่อวันที่ 11 พฤษภาคม 2564 นางสาวพลอยรวี เกริกพันธ์กุล
            ผู้อำนวยการกองขับเคลื่อนดิจิทัลเพื่อเศรษฐกิจ
            เป็นประธานการประชุมเชิงปฏิบัติการเพื่อระดมความคิดเห็นและกำหนดกรอบการศึกษารายละเอียดตัวชี้วัดสำหรับการดำเนินการโครงการ
            Thailand Digital Outlook ระยะที่ 3 โดยครั้งนี้เป็นครั้งที่ 3
            มีวัตถุประสงค์เพื่อ
          </div>

          <div class="mb-4">
            1) เพื่อนำเสนอตัวชี้วัดที่จะดําเนินการในระยะที่ 3 (ปี 2564)
            โดยครอบคลุมถึงทุกมิติตาม OECD Framework ตัวชี้วัดเพิ่มเติมนอกกรอบ
            OECD และมิติการวิเคราะห์ Thailand Digital Outlook
          </div>

          <div class="mb-4">
            2) เพื่อนำเสนอแบบสำรวจที่จะดำเนินการสำรวจในปี 2564 จำนวน 3 แบบ
            ได้แก่ (1) แบบสำรวจภาคประชาชน (2) แบบสำรวจภาคธุรกิจเอกชน และ
            (3)แบบสำรวจ หน่วยงานบริการปฐมภูมิ
          </div>

          <div class="mb-4">
            3) เพื่อระดมความคิดเห็น
            “แนวทางการบูรณาการความร่วมมือระหว่างสำนักงานคณะกรรมการดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งชาติ
            (สดช.) และหน่วยงานที่เกี่ยวข้อง
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #8a8a8a;
}

.linkActive {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #c7352d;
}

.cardCustom {
  background: rgba(199, 179, 232, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}

.content {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}
</style>